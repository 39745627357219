<script setup lang="ts">
import type { Task } from "~/types/task";
const { t } = useI18n();

const { task } = defineProps({
    task: {
        type: Object,
        required: true,
    }
}) as { task: Task };
const dateFrom = computed(() => formatDateRange(task.starting_date))
const dateTo = computed(() => formatDateRange(task.ending_date))
const createdBy = computed(() => formatDate(task.created_at))
const taskBudget = computed(() => {
    if(task?.budget_from && task?.budget_up_to) return `${task.budget_from}-${task?.budget_up_to}$`
    return t('Negotiable price')
})

const formatDateRange = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const options = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
};
const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
};

const taskDates = computed(() => {
    if(task?.starting_date && task?.ending_date) return `${formatDateRange(task.starting_date)} - ${formatDateRange(task.ending_date)}`
    return t('By arrangement')
})
</script>

<template>
    <div class="task flex flex-col justify-between bg-stroke h-full">
        <div class="top_row">
            <div class="task__header">
                <div class="task__date">{{ createdBy }}</div>
                <div class="task__status">{{ $t('Posted') }}</div>
            </div>
            <div class="task__information mt-7">
                <div class="task__category mb-1">{{ task.specification }}</div>
                <div class="task__name font-medium mb-3 break-words">{{ task.name }}</div>
                <div class="task__description break-words">
                    {{ task.description }}
                </div>
            </div>
        </div>

        <div class="bottom_row flex justify-between mt-7">
            <div class="task__price font-semibold">{{ taskBudget }}</div>

            <div class="task__time font-medium">
                <Icon name="ri:calendar-todo-line" size="24px" class="text-sub mr-2" />
                {{ taskDates }}
            </div>
        </div>
    </div>

</template>
<style scoped lang="scss">
.task {
    padding: 12px 16px;
    border-radius: 12px;
}

.task__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task__date,
.task__status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    background: #FFFFFF;
    border-radius: 50px;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #0A0D14;
}

.task__information {
    flex-grow: 1;
}

.task__category {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #525866;
    opacity: 0.72;
}

.task__name {
    font-size: 18px;
    line-height: 24px;
}

.task__description {
    font-size: 14px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.task__price,
.task__time {
    font-size: 14px;
    line-height: 20px;
    color: #0A0D14;
    display: flex;
    align-items: center;
}
</style>