<script setup lang="ts">
import {onClickOutside} from '@vueuse/core'
import {Role} from "~/types/role";

const {role} = defineProps({
  role: {
    type: String,
    required: true,
  }
});

const open = ref(false)

const target = ref(null)

function clear() {
  open.value = false
}

onClickOutside(target, clear)

</script>

<template>
  <div class="search__container px-4 -mx-4 sm:mx-0 sm:px-0 sm:w-full sm:rounded-[20px] items-center">
    <div class="search__column lg:max-w-[250px] xl:max-w-[300px] 2xl:max-w-[330px] lg:block hidden">
      <img src="/img/bg-search-left.png" class="image"/>
    </div>
    <div class="search__column search__column--search mx-auto max-w-full lg:max-w-[50%] xl:max-w-[45%]">
      <h1 class="search__title" v-if="role === Role.CUSTOMER">Find a <span class="search__title--highlight">reliable person</span> <br>for any task</h1>
      <h1 class="search__title" v-if="role === Role.MASTER">Find a <span class="search__title--highlight">reliable task</span> <br>right now</h1>
      <slot></slot>
    </div>
    <div class="search__column lg:max-w-[250px] xl:max-w-[300px] 2xl:max-w-[330px] lg:block hidden">
      <img src="/img/bg-search-right.png" class="image w-full"/>
    </div>

  </div>
</template>

<style scoped lang="scss">
.search {
  margin-top: 80px!important;
  @media(max-width: 640px){
    margin-top: 30px!important;
  }
  &__container {
    background: #0C3B2E;
    height: 500px;
    padding: 48px 20px;
    display: flex;
    justify-content: space-between;
    gap: 46px;
    @media(max-width: 1024px){
      height: auto;
      align-items: center;
    }
    @media(max-width: 640px){
      padding: 40px 20px 30px;
    }
  }

  &__column {
    .image {
      max-height: 100%;
      max-width: 330px;
      width: 100%;
    }

    &--search {
      display: flex;
      flex-direction: column;
      align-content: center;
      gap: 50px;
      width: inherit;
      @media(max-width: 1024px){
        margin-top: 0;
        gap: 23px;
      }
      @media(max-width: 768px){
        width: 100%;
      }
    }
  }

  &__title {
    font-family: Rubik, serif;
    font-size: 46px;
    font-weight: 700;
    line-height: 55.2px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #FFFFFF;
    
    @media(max-width: 1024px){
      font-size: 32px;
      line-height: 45.2px;
    }
    @media(max-width: 640px){
      font-size: 28px;
      line-height: 33.6px;
    }
    &--highlight {
      font-weight: 800;
      color: #FEB902;
    }
  }
}
</style>