<script setup lang="ts">
import type { PropType } from 'vue';
import { Role } from '~/types/role';
const config = useRuntimeConfig()
const {t} = useI18n()
const userProfile = useUserProfileStore();
const authStore = useAuthStore();

const props = defineProps({
    role: {
        type: String as PropType<Role>,
        default: Role.CUSTOMER
    }
})

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

</script>

<template>
    <div class="ys-home-card">
      <p class="font-bold text-[28px] md:text-[38px] mb-8 md:mb-14 text-center" v-html="t('How does YourService work?')"></p>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div class="rounded-[20px] bg-primary-25 px-6 pt-6 h-full">
                <h1 class="text-center font-medium text-[21px] md:text-[26px] mb-5">{{ $t("Choose a specialist") }}</h1>
                <p class="text-sm text-center">
                    {{ $t("Carefully study the portfolio and reviews") }}
                </p>
                <video autoplay loop muted playsinline class="w-[330px] mx-auto mt-12" poster="/img/responce.webp">
                    <source src="/img/Choose a specialist.webm" type="video/webm">
                </video>
            </div>
            <div class="rounded-[20px] bg-primary-25 px-6 pt-6 h-full">
                <h1 class="text-center font-medium text-[21px] md:text-[26px] mb-5">{{ $t("Contact him") }}</h1>
                <p class="text-sm text-center">
                    {{ $t("Carefully study the portfolio and reviews") }}
                </p>
                <video autoplay loop muted playsinline class="w-[330px] h-[256px] mx-auto mt-12" poster="/img/chatting.webp">
                    <source src="/img/Contact him.webm" type="video/webm">
                </video>
            </div>
            <div class="rounded-[20px] bg-primary-25 px-6 pt-6 h-full">
                <h1 class="text-center font-medium text-[21px] md:text-[26px] mb-5">
                    {{ $t("Make a deal") }}</h1>
                <p class="text-sm text-center">
                    {{ $t("Carefully study the portfolio and reviews") }}
                </p>
                <video autoplay loop muted playsinline class="w-[360px] mx-auto mt-12" poster="/img/carousel.webp">
                    <source src="/img/Make a deal.webm" type="video/webm">
                </video>
            </div>
        </div>

        <div v-if="role === Role.CUSTOMER" class="mt-14">
            <UiButton v-if="authStore.isAuth" class="w-full max-w-[450px] h-[56px] bg-primary-500 hover:bg-primary-700 transition-all rounded-[10px] text-lg mx-auto text-center flex items-center" asChild>
                <a href="#index_second_block">
                    {{ $t('Let\'s find a specialist?') }}
                </a>
            </UiButton>
            <LayoutsAuthMainElement
                v-else
                :icon="false" 
                className="w-full max-w-[450px] h-[56px] bg-primary-500 hover:bg-primary-700 transition-all rounded-[10px] text-lg block mx-auto"
                :title="$t('Let\'s find a specialist?')"
            />
        </div>
        <div v-else-if="role === Role.MASTER" class="mt-14">
            <UiButton v-if="authStore.isAuth" class="w-full max-w-[450px] h-[56px] bg-primary-500 hover:bg-primary-700 transition-all rounded-[10px] text-lg mx-auto text-center flex items-center" asChild>
                <a href="#index_second_block">
                    {{ $t('Let\'s find the tasks?') }}
                </a>
            </UiButton>
            <LayoutsAuthMainElement
                v-else
                :icon="false" 
                className="w-full max-w-[450px] h-[56px] bg-primary-500 hover:bg-primary-700 transition-all rounded-[10px] text-lg block mx-auto"
                :title="$t('Let\'s find the tasks?')"
            />
        </div>

        <div class="my-8 sm:my-16 ys-card-element-end px-6 sm:px-12 py-8 sm:py-8 mt-[60px] sm:mt-[80px] lg:mt-[160px] relative">
            <div class="max-w-[440px]">
                <h1 class="ys-home-text mb-6 font-bold text-[21px] md:text-[34px] leading-6 md:leading-9">
                    {{ $t("Always at your fingertips") }} <br>{{ $t("in our app") }}</h1>
                <p class="mb-6 text-sm">
                    {{ $t("Hover your phone's camera over the QR code,") }}<br>{{ $t("to download the app") }}</p>
                <div v-if="config.public.playstoreLink || config.public.appstoreLink" class="flex items-center">
                    <a :href="config.public.playstoreLink" target="_blank">
                        <NuxtImg class="ys-app-emblem" src="/home_page/googleplay.svg" />
                    </a>
                    <a :href="config.public.appstoreLink" target="_blank" class="ltr:ml-6 rtl:mr-6">
                        <NuxtImg class="ys-app-emblem" src="/home_page/appstore.svg" />
                    </a>
                </div>
            </div>
            <NuxtImg class="hidden lg:block absolute bottom-0 ltr:right-[152px] rtl:left-[152px] max-w-[290px]" src="/home_page/phone.png" />
        </div>
    </div>
</template>

<style scoped lang="scss">
.ys-app-emblem {
    width: 161.74px;
    height: 45px;
}


.ys-home-card {
    overflow: hidden;

    .ys-card-element-end {
        border-radius: 36px;
        background: rgba(247, 254, 248, 1);
    }

}

.ys-home-custom-text {
    font-family: Involve, sans-serif;
    font-style: italic;
    color: rgba(58, 62, 89, 1);
    font-size: 39px;
    font-weight: 500;
    line-height: 45px;
    text-align: left;
}

.explain {
    &__header {
        font-family: Rubik, serif;
        font-size: 38px;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: 0.02em;
        text-align: left;

        &--highlight {
            color: #FEB902
        }
    }

    &__item {
        .item__header {
            font-family: 'Rubik', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 31px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #000000;
        }
    }



}
</style>