<template>
  <div class="segment p-1" ref="segment">
    <div class="highlight" :style="highlightStyle"></div>

    <div
        v-for="item in items"
        :key="item.name"
        class="segment-item"
        @click="selectItem(item.value, $event)"
    >
      <span class="segment-name"
            :class="{ 'selected': model === item.value }"
      >{{ item.name }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch, nextTick} from 'vue';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  default: {
    type: String,
    required:true
  }
});

const segment = ref(null);
const model = defineModel();

const highlightStyle = reactive({  left: '0px', width: '0px', opacity: 0 });
const emits = defineEmits(['selected']);

onMounted(() => {
  nextTick(() => {
    document.fonts.ready.then(() => {
        updateHighlight();
    });
  });
});
watch(() => model.value, () => {
  nextTick(() => {
    updateHighlight();
  });
});
watch(() => segment.value, () => {
  nextTick(() => {
    updateHighlight();
  })
})

function selectItem(item, event) {
  model.value = item;
  updateHighlight(event.target);
  emits('selected', item);
}

function updateHighlight(target = null, extraWidth = 32) {
  if (!target && segment.value) {
    target = [...segment.value.querySelectorAll('.segment-item span')].find(
        (el) => el.classList.contains('selected')
    );
  }
  
if (target) {
    highlightStyle.width = `${target.offsetWidth + extraWidth}px`;
    highlightStyle.left = `${target.offsetLeft - extraWidth / 2}px`;
    highlightStyle.opacity = 1;
}
}
</script>
<style scoped lang="scss">
.segment {
  display: flex;
  flex-direction: row;
  background-color: #F6F8FA;
  border-radius: 17px;
  padding: 2px 3px;
  overflow: hidden;
  position: relative;
}

.segment-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px 16px;

  @media(max-width: 768px){
    padding: 8px 16px;
  }
}

.segment-name {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #9C9EAC;
  position: relative;
  z-index: 2;
  transition: color 0.3s ease 0.3s;
  @media(max-width: 640px){
    font-size: 12px;
    line-height: 16px;
  }
}

.segment-name.selected {
  color: #0C3B2E;
}

.highlight {
  position: absolute;
  bottom: 2px;
  height: calc(100% - 4px);
  background: #FFFFFF;
  transition: all 0.3s ease;
  z-index: 1;
  border-radius: 14px;
  box-shadow: 0 5px 20px rgba(0,0,0,.1);
}
</style>